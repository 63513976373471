import { useEffect, useState } from "react"
import { useDetectClickOutside } from "react-detect-click-outside"
import styled from "styled-components"
import Navigator from "./Navigator"
import Icon from "../Icon"

interface dropdownOption {
  icon?: string,
  label: string,
  color?: string,
  link?: string
}

const OptionsContainer = styled.table<{ $showOptionContainerOnLeft?: boolean, $showOptionContainerOnTop?: boolean, $isDark?: boolean }>`
  position: absolute;
  background-color: ${props => props.$isDark ? props.theme.colors.dark : props.theme.colors.white};
  border-radius: 8px;
  box-shadow: 4px 8px 32px 0px #00000033;
  z-index: 100;
  ${props => props.$showOptionContainerOnLeft && {right: '16px'}}
  ${props => props.$showOptionContainerOnTop && {bottom: '48px'}}
  //margin-left: ${props => props.$showOptionContainerOnLeft ? '-110px' : '0px'};
  //transform: translateY(${props => props.$showOptionContainerOnTop ? '-115%' : '0%'});
`

const OptionLabel = styled.span<{ $color?: string }>`
  font-size: 12px;
  margin-left: 5px;
  color: ${props => props.$color ?  props.theme.colors[props.$color] || props.$color : props.theme.colors.gray_800};
`

const OptionContainer = styled.div<{ $color?: string }>`
  display: flex;
  cursor: pointer;
  padding: 12px;

  &:hover {
    ${OptionLabel} {
      color: ${props => props.$color ?  props.theme.colors[props.$color] || props.$color : props.theme.colors.gray_600};
    }
  }
`

export default function Dropdown({ title, options, handleChoose, showOptionContainerOnLeft, showOptionContainerOnTop, isDark }: { title?: dropdownOption, options: dropdownOption[], handleChoose: (value: string) => void, showOptionContainerOnLeft?: boolean, showOptionContainerOnTop?: boolean, isDark?: boolean }) {

  const [selected, setSelected] = useState<dropdownOption>(title ? title : options[0])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function handleSelect(option: dropdownOption) {
    if (!title) {
      setSelected(option)
    }
    setIsOpen(false)
    handleChoose(option.label)
  }


  useEffect(() => {
    if (title) {
      setSelected(title)
    }
  }, [title])

  return (
    <div ref={useDetectClickOutside({ onTriggered: () => setIsOpen(false) })} style={{ position: 'relative' }}>
      <OptionContainer onClick={() => setIsOpen(!isOpen)} $color={selected.color}>
        {selected.icon && (
          <div style={{ marginRight: "4px" }}>
            <Icon path={selected.icon} color={selected.color} width="16px" height="16px" />
          </div>
        )}
        <OptionLabel $color={selected.color}>{selected.label}</OptionLabel>
      </OptionContainer>
      {isOpen && (
        <OptionsContainer $showOptionContainerOnLeft={showOptionContainerOnLeft} $showOptionContainerOnTop={showOptionContainerOnTop} $isDark={isDark}>
          <tbody>
            {options.map(option => (
              <tr key={option.icon + option.label}>
                <td>
                  <OptionContainer $color={option.color} onClick={() => handleSelect(option)}>
                    {option.icon && (
                      <div style={{ marginRight: "4px", marginTop: "4px" }}>
                        <Icon path={option.icon} color={option.color} width="16px" height="16px" />
                      </div>
                    )}
                    {option.label != '' && (
                      <>
                      {option.link ? (
                        <Navigator to={option.link}>
                          <OptionLabel $color={option.color}>{option.label}</OptionLabel>
                        </Navigator>
                      ) : (
                        <a>
                          {/* Need to put it in an a tag because it is still a rendering element for some reason */}
                          <OptionLabel $color={option.color}>{option.label}</OptionLabel>
                        </a>
                      )}
                      </>
                    )}
                  </OptionContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </OptionsContainer>
      )}
    </div>
  )
}