import { getAuth } from "firebase/auth"
import fbConf from "./FirebaseConf"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default async function Comm(endpoint: string, options: {[key: string]: any}) {

  endpoint = endpoint.replace(`/${endpoint.split('/')[1]}`, () => {
    switch (endpoint.split('/')[1]) {
      case 'api-admin':
        return 'https://api.admin.voltie.hu'
      case 'api-charger':
        return 'https://api.charger.voltie.hu'
      case 'api-log':
        return 'https://api.log.voltie.hu'
      case 'api-cdr':
        return 'https://api.cdr.voltie.hu'
      case 'api-notification':
        return 'https://api.notification.voltie.hu'
      case 'api-websocket':
        return 'https://api.websocket.voltie.hu'
      case 'api-firmware':
        return 'https://api.firmware.voltie.hu'
      case 'api-financial':
        return 'https://api.financial.voltie.hu'
      default:
        return ''
    }
  })

  if (!options.headers) {
    options.headers = {}
  }

  if (!options.headers.Authorization) {
    options.headers.Authorization = localStorage.getItem('token') || ''
  }

  options.headers['Access-Control-Allow-Origin'] = '*'
  options.headers['Access-Control-Allow-Credentials'] = 'true'
  options.headers['Access-Control-Allow-Headers'] = 'Content-Type'
  options.headers['Access-Control-Allow-Methods'] = 'GET,POST,PATCH,DELETE,PUT,OPTIONS'
  options.headers['key'] = 'x-api-key'
  
  const response = await fetch(endpoint, options)
  if (response.status == 401) {
    console.log('Error: Invalid token')
    await getAuth(fbConf).signOut()
    localStorage.removeItem('token')
  }

  return response
}



// This version here makes bulletproof sure that a token is available before sending the fetch
// I dont think we can ever get here without a token, but i'll still leave it here just in case it needs to be used

/*import { getAuth } from "firebase/auth"
import fbConf from "./FirebaseConf"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
// @ts-expect-error TS thinks that the return value might be undefined, but this is not right
export default async function Comm(endpoint: string, options: { [key: string]: any }): Promise<Response> {

  endpoint = endpoint.replace(`/${endpoint.split('/')[1]}`, () => {
    switch (endpoint.split('/')[1]) {
      case 'api-admin':
        return 'https://api.admin.voltie.hu'
      case 'api-charger':
        return 'https://api.charger.voltie.hu'
      case 'api-log':
        return 'https://api.log.voltie.hu'
      case 'api-cdr':
        return 'https://api.cdr.voltie.hu'
      case 'api-notification':
        return 'https://api.notification.voltie.hu'
      case 'api-websocket':
        return 'https://api.websocket.voltie.hu'
      case 'api-firmware':
        return 'https://api.firmware.voltie.hu'
      default:
        return ''
    }
  })

  if (!options.headers) {
    options.headers = {}
  }

  if (!options.headers.Authorization) {

    if (!localStorage.getItem('token')) {

      // If there is no token in localstorage, try and wait for it, than do the fetch when found
      let checkCounter = 0
      const checkIfTokenIsAvailableInterval = setInterval(() => {
        console.log('Checking for token')
        checkCounter += 1
        if (localStorage.getItem('token')) {
          clearInterval(checkIfTokenIsAvailableInterval)
          options.headers.Authorization = localStorage.getItem('token')
          return doTheFetch(endpoint, options)
        }
        if (checkCounter >= 100) {
          // Token not found after 10 seconds, something is not right, just try and do the fetch anywas so it logs the user out
          clearInterval(checkIfTokenIsAvailableInterval)
          options.headers.Authorization = ''
          return doTheFetch(endpoint, options)
        }
      }, 100)

    } else {
      // If the token is in the localstorage without waiting for it, just do the fetch
      options.headers.Authorization = localStorage.getItem('token')
      return doTheFetch(endpoint, options)
    }

  } else {
    // If there is already a token in the Authorization header, just do the fetch with that
    return doTheFetch(endpoint, options)
  }
}

async function doTheFetch(endpoint: string, options: { [key: string]: { [key: string]: string } }) {
  options.headers['Access-Control-Allow-Origin'] = '*'
  options.headers['Access-Control-Allow-Credentials'] = 'true'
  options.headers['Access-Control-Allow-Headers'] = 'Content-Type'
  options.headers['Access-Control-Allow-Methods'] = 'GET,POST,PATCH,DELETE,PUT,OPTIONS'
  options.headers['key'] = 'x-api-key'
  
  const response = await fetch(endpoint, options)
  if (response.status == 401) {
    console.log('Error: Invalid token')
    console.log(options.headers.Authorization)
    await getAuth(fbConf).signOut()
    localStorage.removeItem('token')
    location.href = '/login'
  }

  return response
}*/