import { ReactElement } from "react"
import styled from "styled-components"

const ButtonElement = styled.button<{ $width: string, $bgcolor?: string, $labelcolor?: string, $bordercolor?: string }>`
  border: ${props => props.$bordercolor ? `1px solid ${props.$bordercolor}` : 'none'};
  background: none;
  background-color: ${props => props.$bgcolor || props.theme.colors.dark};
  color: ${props => props.$labelcolor || props.theme.colors.white};
  font-weight: 600;
  border-radius: 12px;
  width: ${props => props.$width};
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: pointer;
  outline: none;

  &:active {
    transform: scale(.99);
  }
`

export default function Button({ label, width, onclick, bgcolor, labelcolor, bordercolor }: { label: string | ReactElement, width: string, onclick: () => void, bgcolor?: string, labelcolor?: string, bordercolor?: string }) {
  return (
    <ButtonElement onClick={onclick} value={label.toString()} $width={width} $bgcolor={bgcolor} $labelcolor={labelcolor} $bordercolor={bordercolor}>{label}</ButtonElement>
  )
}