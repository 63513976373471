import { useState, useRef } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';

const Container = styled.div<{ $width: string }>`
  width: ${props => props.$width};
  position: relative;
`

const Track = styled.div<{ $bgColorLeft: string, $bgColorRight: string, $height: string }>`
  width: 100%;
  height: ${props => props.$height};
  border-radius: 40px;
  position: relative;
  background: ${props => `linear-gradient(90deg, ${props.$bgColorLeft} 0%, ${props.$bgColorRight} 100%)`};
`

const Thumb = styled.div<{ $thumbPosition: number, $isSliding: boolean, $height: string }>`
  width: ${props => `calc(${props.$height} - 4px)`};
  height: ${props => `calc(${props.$height} - 4px)`};
  margin: 2px;
  border-radius: ${props => `calc(${props.$height} - 4px)`};
  background-color: ${props => props.theme.colors.white};
  position: absolute;
  left: ${(props) => props.$thumbPosition}px;
  cursor: pointer;
  transition: ${(props) => (props.$isSliding ? 'none' : 'left 0.3s ease')};
`

const IconContainer = styled.div`
  width: calc(100% - 24%);
  height: calc(100% - 24%);
  margin-left: 12%;
  margin-top: 12%;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
`

const ConfirmText = styled.span<{ $height: string }>`
  position: absolute;
  width: 100%;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
  font-weight: 700;
  font-size: 13px;
  //margin-top: 7px;
  margin-top: ${props => `calc((${props.$height} - 18px) / 2)`};
  color: ${props => props.theme.colors.white};
`

export default function SlideToConfirm({ onConfirm, width, height, label, icon, iconColor, bgColorLeft, bgColorRight }: { onConfirm: () => void, width: string, height?: string, label: string, icon: string, iconColor?: string, bgColorLeft: string, bgColorRight: string }) {
  const [thumbPosition, setThumbPosition] = useState(0)
  const [isSliding, setIsSliding] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const containerRef = useRef(null);

  const handleMouseDown = () => {
    setIsSliding(true)
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>, height: number) => {
    if (isSliding && !isConfirmed) {
      //@ts-expect-error This event can only occur if the container is rendered, the ref exists for sure
      const containerRect = containerRef.current.getBoundingClientRect()
      const newThumbPosition = Math.min(Math.max(e.clientX - containerRect.left - 14, 0), containerRect.width - height)
      setThumbPosition(newThumbPosition)
    }
  }

  const handleMouseUp = (height: number) => {
    if (isSliding) {
      setIsSliding(false)
      //@ts-expect-error This event can only occur if the container is rendered, the ref exists for sure
      if (thumbPosition >= containerRef.current.getBoundingClientRect().width - height) {
        setIsConfirmed(true)
        onConfirm()
      } else {
        setThumbPosition(0)
      }
    }
  }

  return (
    <Container $width={width} ref={containerRef} onMouseMove={(e) => handleMouseMove(e, parseInt(height || '32px'))} onMouseUp={() => handleMouseUp(parseInt(height || '32px'))} onMouseLeave={() => handleMouseUp(parseInt(height || '32px'))}>
      <Track $bgColorLeft={bgColorLeft} $bgColorRight={bgColorRight} $height={height || '32px'}>
        <ConfirmText $height={height || '32px'}>{label}</ConfirmText>
        <Thumb $thumbPosition={thumbPosition} $isSliding={isSliding} onMouseDown={handleMouseDown} $height={height || '32px'}>
          <IconContainer>
            <Icon path={icon} width="100%" height="100%" color={iconColor} />
          </IconContainer>
        </Thumb>
      </Track>
    </Container>
  )
}