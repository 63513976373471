import styled, { ThemeProvider } from "styled-components"
import InputWithLabel from "../components/InputWithLabel"
import { useEffect, useState } from "react"
import PasswordInputWithLabel from "../components/PasswordInputWithLabel"
import WhiteSpace from "../components/Whitespace"
import Checkbox from "../components/Checkbox"
import Button from "../components/Button"
import { useNavigate, useSearchParams } from "react-router-dom"
import Dropdown from "../components/Dropdown"
import { useTranslation } from "react-i18next"
import GlobalStyle from "../GlobalStyle"
import { lightTheme, darkTheme } from "../GlobalTheme"
import { browserLocalPersistence, browserSessionPersistence, confirmPasswordReset, getAuth, sendPasswordResetEmail, setPersistence, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, OAuthProvider } from "firebase/auth"
import fbConf from "../FirebaseConf"
import Comm from "../Comm"
import Icon from "../Icon"

const Container = styled.div`
  background-color: #181C32;
  width: 100vw;
  height: 100vh;
`

const Shadow = styled.div<{ $color1: string, $color2: string, $left: string }>`
  position: absolute;
  width: 35vw;
  height: 35vw;
  left: ${props => props.$left};
  top: calc((100vh - 35vw) / 2);
  background-image: linear-gradient(${props => props.$color1} 0%, ${props => props.$color2} 100%);
  border-radius: 100vw;
  filter: blur(70px);
`

const ChargerFaceContainer = styled.div`
  position: absolute;
  width: 35vw;
  height: 35vw;
  left: 10vw;
  top: calc((100vh - 35vw) / 2);
`

const LoginContainer = styled.div`
  position: absolute;
  width: calc(30vw - 8vw);
  top: calc((100vh - 34vw) / 2);
  left: 60vw;
  background-color: ${props => props.theme.colors.white};
  padding: 4vw 4vw 2vw 4vw;
  border-radius: 1.25vw;
  box-shadow: 0px 0px 5px 5px #0C0C0D1A;
`

const TitleLabel = styled.p`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.gray_900};
  font-weight: 600;
  font-size: 18px;
  margin-top: 1.5vw;
  margin-bottom: 1vw;
`

const SubtitleLabel = styled.p`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.gray_900};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1vw;
`

const SubtitleLabelLeft = styled.p`
  text-align: left;
  color: ${props => props.theme.colors.gray_900};
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 1vw;
  width: 95%;
  margin-left: 2.5%;
`

const ForgotPasswordLabel = styled.p`
  float: right;
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.primary};
  margin-top: 0px;
  cursor: pointer;
`

const ErrorMessage = styled.p`
  width: 100%;
  text-align: left;
  font-size: 12px;
  color: ${props => props.theme.colors.danger};
`

const CopyRight = styled.p`
  position: absolute;
  color: #5E6278;
  font-weight: 400;
  font-size: 12px;
  width: 5vw;
  margin-left: 47.5vw;
  text-align: center;
  bottom: 5vh;
  cursor: pointer;
`

const DropdownTable = styled.table`
  width: 100%;
`

const BackButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  left: 32px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 26px;
  right: 32px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const EmailLabel = styled.p`
  width: 100%;
  text-align: center;
  color: ${props => props.theme.colors.gray_900};
  font-weight: 600;
  font-size: 15px;
  margin-top: 1.5vw;
  margin-bottom: 1vw;
`

const PasswordStrengthIndicatorCell = styled.td<{ $status: string }>`
  height: 4px;
  border-radius: 10px;
  background-color: ${props => props.$status == 'red' ? props.theme.colors.danger : props.$status == 'green' ? props.theme.colors.success : props.theme.colors.gray_500};
`

export default function Login() {
  const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const [searchParams] = useSearchParams()
  const [resettoken, setResettoken] = useState<string | null>(searchParams.get('oobCode'))

  const [emailInput, setEmailInput] = useState<string>('')
  const [passwordInput, setPasswordInput] = useState<string>('')
  const [isLoginWithEmailAndPassword, setIsLoginWithEmailAndPassword] = useState<boolean>(false)
  const [isRememberMe, setIsRememberMe] = useState<boolean>(true)
  const [isShowErrorMessage, setIsShowErrorMEssage] = useState<boolean>(false)
  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false)
  const [forgotPasswordEmailInput, setForgotPasswordEmailInput] = useState<string>('')
  const [isRecoveryEmailSent, setIsRecoveryEmailSent] = useState<boolean>(false)
  const [newPasswordInput, setNewPasswordInput] = useState<string>('')
  const [newPasswordRepeatInput, setNewPasswordRepeatInput] = useState<string>('')
  const [newPasswordStrength, setNewPasswordStrength] = useState<number>(0) // between 0 and 4
  const [isShowPasswordsDoNotMatchErrorMessage, setIsShowPasswordsDoNotMatchErrorMessage] = useState<boolean>(false)
  const [isShowPasswordTooWeakErrorMessage, setIsShowPasswordTooWeakErrorMEssage] = useState<boolean>(false)
  const [mousePos, setMousePos] = useState<{ x: number, y: number }>({ x: 0, y: 0 })
  const [isChargerWink, setIsChargerWink] = useState<boolean>(false)
  const [isUnknownEmailAtPasswordReset, setIsUnknownEmailAtPasswordReset] = useState<boolean>(false)

  const [theme, setTheme] = useState<string>(localStorage.getItem('preferedTheme') == 'system' ? (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light') : localStorage.getItem('preferedTheme') as string)

  useEffect(() => {
    getAuth(fbConf).onAuthStateChanged(user => {
      if (user) {
        let checkCounter = 0
        const checkIfTokenIsAvailableInterval = setInterval(() => {
          checkCounter += 1
          if (localStorage.getItem('token')) {
            clearInterval(checkIfTokenIsAvailableInterval)
            navigate('/')
          }
          if (checkCounter >= 100) {
            // Token not found after 10 seconds, something is not right
            clearInterval(checkIfTokenIsAvailableInterval)
          }
        }, 100)
      }
    })
  }, [])

  async function handleLogin(method: 'email' | 'apple' | 'google') {
    const auth = getAuth(fbConf)

    if (isRememberMe) {
      await setPersistence(auth, browserLocalPersistence)
    } else {
      await setPersistence(auth, browserSessionPersistence)
    }

    try {
      if (method == 'email') {
        await signInWithEmailAndPassword(auth, emailInput, passwordInput)
      } else if (method == 'google') {
        //await signInWithRedirect(auth, new GoogleAuthProvider())
        await signInWithPopup(auth, new GoogleAuthProvider())
      } else if (method == 'apple') {
        await signInWithPopup(auth, new OAuthProvider('apple.com'))
      }
      setIsShowErrorMEssage(false)


      // If user is admin, get the admin token from the server, if not use the token from firebase as the token, either way, save the token to localStorage
      if ((await auth.currentUser?.getIdTokenResult())?.claims.admin) {
        const firebaseToken = await auth.currentUser?.getIdToken()
        const response = await Comm('/api-admin/login/admin', { method: "POST", headers: { "Content-Type": "application/json", "Authorization": `Bearer ${firebaseToken}` } })
        const token = await response?.text()
        if (token) { localStorage.setItem('token', token) }
      } else {

        const idToken = await auth.currentUser?.getIdToken()
        if (idToken) {
          localStorage.setItem('token', idToken)
        }
      }

      localStorage.setItem('lastLogin', new Date().toString().split(' ').splice(1, 4).join(' '))
    } catch (error) {
      console.log(error)
      setIsShowErrorMEssage(true)
      setPasswordInput('')
    }
  }

  // Set language to preferred
  useEffect(() => {
    if (localStorage.getItem('preferedLanguage') == 'hu') {
      i18n.changeLanguage('hu')
    } else {
      i18n.changeLanguage('en')
    }
  }, [])

  function handleChooseDropdownOption(label: string) {
    switch (label) {
      case 'Magyar':
        i18n.changeLanguage('hu')
        localStorage.setItem('preferedLanguage', 'hu')
        break
      case 'English':
        i18n.changeLanguage('en')
        localStorage.setItem('preferedLanguage', 'en')
        break
      case t('login.light'):
        setTheme('light')
        localStorage.setItem('preferedTheme', 'light')
        break
      case t('login.dark'):
        setTheme('dark')
        localStorage.setItem('preferedTheme', 'dark')
        break
      case t('login.system'):
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          setTheme('dark')
        } else {
          setTheme('light')
        }
        localStorage.setItem('preferedTheme', 'system')
        break
      case 'help@voltie.eu':
        window.open(`mailto:help@voltie.eu?subject=Voltie Dashboard visszajelzés`)
    }
  }

  async function handleSubmitForgotPassword() {
    try {
      await sendPasswordResetEmail(getAuth(fbConf), forgotPasswordEmailInput)
      setIsRecoveryEmailSent(true)
      setIsUnknownEmailAtPasswordReset(false)
    } catch (err) {
      setIsUnknownEmailAtPasswordReset(true)
    }
  }

  async function handleResetPassword() {
    setIsShowPasswordsDoNotMatchErrorMessage(newPasswordInput != newPasswordRepeatInput)
    setIsShowPasswordTooWeakErrorMEssage(newPasswordStrength < 1)

    if (newPasswordInput == newPasswordRepeatInput && newPasswordStrength > 0 && resettoken) {
      await confirmPasswordReset(getAuth(fbConf), resettoken, newPasswordInput)
      setIsForgotPassword(false)
      setIsRecoveryEmailSent(false)
      setResettoken(null)
      navigate('/login')
    }
  }

  // new password validation
  useEffect(() => {
    const passowordValidationRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,100}$/
    let newPasswordStrength = 0
    if (passowordValidationRegex.test(newPasswordInput)) {
      newPasswordStrength = 1
      if (newPasswordInput.length > 20) {
        newPasswordStrength = 4
      } else if (newPasswordInput.length > 15) {
        newPasswordStrength = 3
      } else if (newPasswordInput.length > 10) {
        newPasswordStrength = 2
      }
    }
    setNewPasswordStrength(newPasswordStrength)
  }, [newPasswordInput])

  function handleMouseMove(e: React.MouseEvent<HTMLDivElement>) {
    setMousePos({ x: e.pageX, y: e.pageY })
  }

  function handleStartWink() {
    setIsChargerWink(true)
    setTimeout(() => setIsChargerWink(false), 300)
  }

  // Detect light/dark theme change
  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      if (localStorage.getItem('preferedTheme') == 'system') {
        event.matches ? setTheme('dark') : setTheme('light')
      }
    })
  }, [])

  return (
    <ThemeProvider theme={theme == 'dark' ? darkTheme : lightTheme}>
      <GlobalStyle />
      <Container onMouseMove={handleMouseMove} onClick={handleStartWink}>
        <Shadow $color1='rgba(0, 194, 255, .1)' $color2='#4AB1FC' $left='10vw' />
        <Shadow $color1='rgba(0, 194, 255, .4)' $color2='#901b6f' $left='55vw' />
        <ChargerFaceContainer>
          <Icon path="login.chargerFace" width="100%" height="100%" />
        </ChargerFaceContainer>
        <ChargerFaceContainer style={{ marginLeft: `${(mousePos.x - (window.screen.width / 3)) / 25}px`, marginTop: `${(mousePos.y - (window.screen.height / 2)) / 25}px` }}>
          <Icon path={isChargerWink ? "login.chargerWink" : "login.chargerEye"} width="100%" height="100%" />
        </ChargerFaceContainer>
        <LoginContainer>
          {!resettoken ? (
            <>
              {!isForgotPassword && (
                <>
                  {isLoginWithEmailAndPassword ? <>
                    {/* Standard Email And Password Login Page */}
                    <BackButtonContainer onClick={() => setIsLoginWithEmailAndPassword(false)}>
                      <Icon path="general.backArrow" width="100%" height="100%" />
                    </BackButtonContainer>
                    <Icon path="general.logoDashboardVertical" width="calc((100% + 8vw) * .32)" marginleft="calc((100% - ((100% + 8vw) * .32)) / 2)" />
                    <TitleLabel>{t('login.signIn')}</TitleLabel>
                    <InputWithLabel value={emailInput} onInput={(value) => setEmailInput(value)} label='Email *' width="100%" />
                    <WhiteSpace height="1vw" />
                    <PasswordInputWithLabel value={passwordInput} onInput={(value) => setPasswordInput(value)} label={`${t('login.password')} *`} width="100%" isError={isShowErrorMessage} />
                    {isShowErrorMessage && (
                      <ErrorMessage>{t('login.incorrectEmailOrPassword')}</ErrorMessage>
                    )}
                    <WhiteSpace height="1vw" />
                    <ForgotPasswordLabel onClick={() => setIsForgotPassword(true)}>{t('login.forgotPassword')}?</ForgotPasswordLabel>
                    <Checkbox label={t('login.rememberMe')} value={isRememberMe} onClick={() => setIsRememberMe(!isRememberMe)} />
                    <WhiteSpace height="2vw" />
                    <Button onclick={() => handleLogin('email')} label={t('login.signIn')} width='100%' />
                  </> : <>
                    {/* Login Method Chooser Page (Google, Apple, Email) */}
                    <Icon path="general.logoDashboardVertical" width="calc((100% + 8vw) * .32)" marginleft="calc((100% - ((100% + 8vw) * .32)) / 2)" />
                    <TitleLabel>{t('login.goodToSeeYou')}</TitleLabel>
                    <Button onclick={() => handleLogin('apple')} label={<>
                      <Icon path={"login.appleLogoSmall"} width="18px" height="18px" margintop="-4px" marginbottom="-4px" marginright="12px" color="white" />
                      {t('login.continueWithApple')}
                    </>} width='100%' />
                    <WhiteSpace height="1vw" />
                    <Button onclick={() => handleLogin('google')} bgcolor="transparent" labelcolor="dark" bordercolor="#181C32" label={<>
                      <Icon path={"login.googleLogoSmall"} width="18px" height="18px" margintop="-4px" marginbottom="-4px" marginright="12px" color="white" />
                      {t('login.continueWithGoogle')}
                    </>} width='100%' />
                    <WhiteSpace height="1vw" />
                    <div style={{ display: 'flex' }}>
                      <div style={{ width: '42.5%', height: '0px', marginTop: '7px', borderBottom: '1px solid #E1E3EA' }} />
                      <p style={{ width: '15%', textAlign: 'center', margin: '0px', fontWeight: '400', fontSize: '14px', color: '#A1A5B7' }}>{t('login.or')}</p>
                      <div style={{ width: '42.5%', height: '0px', marginTop: '7px', borderBottom: '1px solid #E1E3EA' }} />
                    </div>
                    <WhiteSpace height="1vw" />
                    <Button onclick={() => setIsLoginWithEmailAndPassword(true)} bgcolor="transparent" labelcolor="dark" bordercolor="#181C32" label={<>
                      <Icon path={"login.mail"} width="18px" height="18px" margintop="-4px" marginbottom="-4px" marginright="12px" color="primary" />
                      {t('login.continueWithEmail')}
                    </>} width='100%' />
                  </>}
                </>
              )}
              {isForgotPassword && (
                <>
                  {!isRecoveryEmailSent ? (
                    <>
                      {/* Page To Enter Email Address For Password Recovery */}
                      <BackButtonContainer onClick={() => setIsForgotPassword(false)}>
                        <Icon path="general.backArrow" width="100%" height="100%" />
                      </BackButtonContainer>
                      <CloseButtonContainer onClick={() => setIsForgotPassword(false)}>
                        <Icon path="general.x" width="100%" height="100%" />
                      </CloseButtonContainer>
                      <Icon path="login.forgotPassword" width="96px" height="96px" marginleft="calc((100% - 96px) / 2)" />
                      <TitleLabel>{t('login.forgotPassword')}</TitleLabel>
                      <SubtitleLabel>{t('login.enterEmailOrPhoneNumberUsedDuringSignUp')}</SubtitleLabel>
                      <WhiteSpace height=".5vw" />
                      <InputWithLabel value={forgotPasswordEmailInput} onInput={(value) => setForgotPasswordEmailInput(value)} label='Email *' width="100%" isError={isUnknownEmailAtPasswordReset} />
                      {isUnknownEmailAtPasswordReset && (
                        <ErrorMessage>{t('login.unknownEmail')}</ErrorMessage>
                      )}
                      <WhiteSpace height="2vw" />
                      <Button onclick={handleSubmitForgotPassword} label={t('login.submit')} width='100%' />
                    </>
                  ) : (
                    <>
                      {/* Page That Informs User That The Password Recovery Email Has Been Sent */}
                      <BackButtonContainer onClick={() => setIsRecoveryEmailSent(false)}>
                        <Icon path="general.backArrow" width="100%" height="100%" />
                      </BackButtonContainer>
                      <CloseButtonContainer onClick={() => { setIsForgotPassword(false); setIsRecoveryEmailSent(false) }}>
                        <Icon path="general.x" width="100%" height="100%" />
                      </CloseButtonContainer>
                      <Icon path="login.checkYourInbox" width="96px" height="96px" marginleft="calc((100% - 96px) / 2)" />
                      <TitleLabel>{t('login.checkYourEmail')}</TitleLabel>
                      <SubtitleLabel>{t('login.sentInstructionsOnHowToResetYourPassword')}</SubtitleLabel>
                      <EmailLabel>{forgotPasswordEmailInput}</EmailLabel>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {/* Page To Set A New Password (currently unused, because firebase cant redirect to our custom reset link, beacuse mobile uses the same thing) */}
              <Icon path="login.setNewPassword" width="96px" height="96px" marginleft="calc((100% - 96px) / 2)" />
              <TitleLabel>{t('login.setupNewPassword')}</TitleLabel>
              <PasswordInputWithLabel value={newPasswordInput} onInput={(value) => setNewPasswordInput(value)} label={`${t('login.password')} *`} width="100%" />
              <WhiteSpace height=".5vw" />
              <table style={{ width: '95%', marginLeft: '2.5%' }}>
                <tbody>
                  <tr>
                    <PasswordStrengthIndicatorCell $status={newPasswordStrength == 0 ? newPasswordInput.length > 0 ? 'red' : 'gray' : 'green'} ></PasswordStrengthIndicatorCell>
                    <PasswordStrengthIndicatorCell $status={newPasswordStrength > 1 ? 'green' : 'gray'} ></PasswordStrengthIndicatorCell>
                    <PasswordStrengthIndicatorCell $status={newPasswordStrength > 2 ? 'green' : 'gray'} ></PasswordStrengthIndicatorCell>
                    <PasswordStrengthIndicatorCell $status={newPasswordStrength > 3 ? 'green' : 'gray'} ></PasswordStrengthIndicatorCell>
                  </tr>
                </tbody>
              </table>
              <SubtitleLabelLeft>{t('login.use8OrMoreCharactersWithAMixOfLettersNumbersSymbols')}</SubtitleLabelLeft>
              <PasswordInputWithLabel value={newPasswordRepeatInput} onInput={(value) => setNewPasswordRepeatInput(value)} label={`${t('login.repeatPassword')} *`} width="100%" />
              {isShowPasswordsDoNotMatchErrorMessage && (
                <ErrorMessage>{t('login.twoPasswordsDoNotMatch')}</ErrorMessage>
              )}
              {isShowPasswordTooWeakErrorMessage && (
                <ErrorMessage>{t('login.passwordTooWeak')}</ErrorMessage>
              )}
              <WhiteSpace height="2vw" />
              <Button onclick={handleResetPassword} label={t('login.save')} width='100%' />
            </>
          )}
          <WhiteSpace height="2vw" />
          <DropdownTable>
            <tbody>
              <tr>
                <td style={{ width: '33%', paddingLeft: '.5vw' }}>
                  <Dropdown handleChoose={handleChooseDropdownOption} title={{ icon: i18n.language == 'en' ? "login.flagUk" : "login.flagHun", label: i18n.language == 'en' ? 'English' : 'Magyar' }} options={[{ icon: "login.flagUk", label: 'English' }, { icon: "login.flagHun", label: 'Magyar' }]} />
                </td>
                <td style={{ width: '33%', paddingLeft: '.75vw' }}>
                  <Dropdown handleChoose={handleChooseDropdownOption} title={{ icon: localStorage.getItem('preferedTheme') == 'system' ? "login.systemTheme" : theme == 'dark' ? "login.darkmode" : "login.lightmode", label: t('login.mode'), color: "gray_600" }} options={[{ icon: "login.lightmode", label: t('login.light'), color: "gray_600" }, { icon: "login.darkmode", label: t('login.dark'), color: "gray_600" }, { icon: "login.systemTheme", label: t('login.system'), color: "gray_600" }]} />
                </td>
                <td style={{ width: '33%' }}>
                  <Dropdown handleChoose={handleChooseDropdownOption} title={{ icon: "login.mail", label: t('login.contactUs'), color: "gray_600" }} options={[{ icon: "login.mail", label: 'help@voltie.eu', color: "gray_600" }]} />
                </td>
              </tr>
            </tbody>
          </DropdownTable>
        </LoginContainer>
        <a href="https://www.voltie.eu/">
          <CopyRight>© 2025 Voltie</CopyRight>
        </a>
      </Container>
    </ThemeProvider>
  )
}
