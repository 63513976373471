import { useEffect } from "react"
import { useNavigate } from "react-router-dom"


export default function Dashboard() {

  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/chargers')
    }, 10)
  }, [])

  return (
    <></>
  )
}