import { t } from "i18next"

export default function secondsToHumanReadable(seconds: number) {
  if (seconds <= 3600) {
    return `${Math.floor(seconds / 60)}${t('charger.m')}`
  }

  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - (hours * 3600)) / 60)
  if (hours == 0) {
    return `${hours}${t('charger.m')}`
  } else {
    return `
      ${hours}${t('charger.h')} 
      ${minutes}${t('charger.m')} 
    `
  }
}