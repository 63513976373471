import { useEffect, useState } from "react"
import { useOutletContext, useParams } from "react-router-dom"
import ChargerType from "../../types/ChargerType"
import Comm from "../../Comm"
import styled from "styled-components"
import PageTitle from "../../components/PageTitle"
import WhiteSpace from "../../components/Whitespace"
import DateRangePicker from "../../components/DateRangePicker"
import { useTranslation } from "react-i18next"
import LogType from "../../types/LogType"
import ButtonWithIcon from "../../components/ButtonWithIcon"
import Notification from "../../components/Notification"
import Navigator from "../../components/Navigator"
import Table from "../../modules/Table"
import { TableCellTypes } from "../../enums/TableCellTypes"
import Icon from "../../Icon"

const DateRangePickerContainer = styled.div`
  position: absolute;
  margin-left: 21vw;
  margin-top: 15px;
  z-index: 1;
`

const RefreshTimerLabel = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${props => props.theme.colors.gray_600};
  margin-top: 4px;
  text-align: center;
`

const LogCode = styled.p<{ $code: string }>` // normal / warning / error
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.$code == 'normal' ? props.theme.colors.black : props.theme.colors.white};
  border-radius: 40px;
  width: 70px;
  line-height: 22px;
  text-align: center;
  margin: 0px;
  background-color: ${props => {
    switch (props.$code) {
      case 'normal':
        return 'transparent'
      case 'warning':
        return props.theme.colors.warning
      case 'error':
        return props.theme.colors.danger
    }
  }};
`

const LogIndicator = styled.div<{ $code: string }>` // normal / warning / error
  width: 3px;
  height: 28px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 3px;
  background-color: ${props => {
    switch (props.$code) {
      case 'normal':
        return props.theme.colors.primary
      case 'warning':
        return props.theme.colors.warning
      case 'error':
        return props.theme.colors.danger
    }
  }};
`

const ChargerDetailsTable = styled.table`
  width: calc(100%);
  background-color: ${props => props.theme.colors.gray_300};
  border-radius: 16px;
`

const ChargerDetailsTitle = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: ${props => props.theme.colors.gray_500};
  margin: 0px;
  margin-bottom: 4px;
  margin-top: 20px;
`

const ChargerDetailsValue = styled.p`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.colors.gray_900};
  margin: 0px;
`

const ChargerDetailsGoToChargerContainer = styled.div`
  position: absolute;
  right: 0px;
  display: flex;
  margin-right: 16px;
  cursor: pointer;
`

const ChargerDetailsGoToChargerLabel = styled.p`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.primary};
  margin: 0px;
  margin-top: 30px;
`

const ToolbarButtonContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 26px;
  display: flex;
`

export default function ChargerLogs() {
  const { chargerId } = useParams()
  const { t } = useTranslation()
  const { theme } = useOutletContext<{ theme: string }>()

  const [charger, setCharger] = useState<ChargerType>()
  const [logs, setLogs] = useState<LogType[]>([])
  const [lastRefresh, setLastRefresh] = useState<string>('')
  const [selectedDateRange, setSelectedDateRange] = useState<Date[]>()
  const [notification, setNotification] = useState<string>('')

  useEffect(() => {
    loadData()
  }, [])

  async function loadData() {
    const charger = await fetchCharger()
    const availableLogDays = await fetchAvailableLogDays()

    if (charger && availableLogDays) {
      // Defult date range for past 3 days:
      //const defaultDateRange = [new Date(new Date((availableLogDays[0])).setDate(new Date(availableLogDays[0]).getDate() - 2)), new Date(availableLogDays[0])]
      const defaultDateRange = [new Date(availableLogDays[0])]
      setSelectedDateRange(defaultDateRange)
      setCharger({ ...charger, availableLogDays: availableLogDays })
      await fetchLogs({ ...charger, availableLogDays: availableLogDays }, defaultDateRange)

    } else {
      console.log('Could not fetch charger or available log days')
    }
  }

  async function fetchCharger() {
    const response = await Comm('/api-charger/charger', {
      headers: { "Charger_id": chargerId, "Content-Type": "application/json" }
    })
    return await response.json()
  }

  async function fetchAvailableLogDays() {
    // TODO: Replace with endpoint that gives back only the neccesery chargers available days
    const response = await Comm('/api-log/charger/log/frontend/available', {})
    const result: { chargerId: string, availableDays: string[] }[] = await response.json()

    for (const charger of result) {
      if (charger.chargerId == chargerId) {
        return charger.availableDays
      }
    }
    return []
  }

  async function fetchLogs(charger: ChargerType, dateRange: Date[]) {
    const daysToFetch: string[] = []
    if (dateRange.length == 1) {
      daysToFetch.push(dateRange[0].toISOString().split('T')[0])
    } else {
      const endDate = new Date(dateRange[1].getTime())
      endDate.setDate(endDate.getDate() + 1)
      for (const date = new Date(dateRange[0].getTime()); date <= endDate; date.setDate(date.getDate() + 1)) {
        daysToFetch.push(new Date(date).toISOString().split('T')[0])
      }
    }

    const allLogsForSelectedDateRange: LogType[] = []
    for (const day of daysToFetch) {
      if (charger.availableLogDays.includes(day)) {
        const allLogsForDay = await Comm(`/api-log/charger/log/frontend?day=${day}`, { headers: { "Charger_id": chargerId } })
        const allLogsForDayResult: LogType[] = await allLogsForDay.json()
        allLogsForDayResult.map(log => log.date = day)
        allLogsForSelectedDateRange.push(...allLogsForDayResult)
      }
    }
    allLogsForSelectedDateRange.reverse()
    setLogs(allLogsForSelectedDateRange)
    setLastRefresh(new Date().toString().split(' ')[4])
  }


  async function handelRefresh() {
    if (charger && selectedDateRange) {
      setLogs([])

      // Calling loaddata instead of only fetching logs to reset the daterange to default (including newest logs on new day)
      //fetchLogs(charger, selectedDateRange)
      await loadData()
    }
  }

  function handleChooseDateRange(range: Date[]) {
    if (charger) {
      setSelectedDateRange(range)
      setLogs([])
      fetchLogs(charger, range)
    }
  }

  // TODO: Would be nice to have a util function for these
  async function handleChargerUpdateLog() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 15, "sub_cmd": 3 })
    })

    if (response.ok) {
      setNotification(await response.json())
    } else {
      setNotification(await response.text())
    }
  }

  async function handleChargerReboot() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 15, "sub_cmd": 10 })
    })

    if (response.ok) {
      setNotification(await response.json())
    } else {
      setNotification(await response.text())
    }
  }

  async function handleChargerUpdate() {
    const response = await Comm('/api-websocket/mobile', {
      method: "POST",
      headers: {
        "Charger_id": chargerId,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ "msg_type": 15, "sub_cmd": 1 })
    })

    if (response.ok) {
      setNotification(await response.json())
    } else {
      setNotification(await response.text())
    }
  }

  return (
    <>
      {notification && (
        <Notification setState={setNotification} label={JSON.stringify(notification)} />
      )}
      <PageTitle label={t('chargerLogs.chargerLOGs')} />
      <ToolbarButtonContainer>
        <div>
          <ButtonWithIcon icon="general.refresh" label={t('charger.refresh')} onClick={handelRefresh} backgroundColor="success" iconColor="white" />
          <RefreshTimerLabel>{lastRefresh}</RefreshTimerLabel>
        </div>
        <WhiteSpace width="8px" />
        <div>
          <ButtonWithIcon icon="general.export" iconColor="white" label={t('chargerLogs.uploadLog')} onClick={handleChargerUpdateLog} backgroundColor="dark" />
        </div>
        <WhiteSpace width="8px" />
        <div>
          <ButtonWithIcon icon="general.refresh" iconColor="white" label={t('chargerLogs.reboot')} onClick={handleChargerReboot} backgroundColor="dark" />
        </div>
        <WhiteSpace width="8px" />
        <div>
          <ButtonWithIcon icon="general.wrench" iconColor="white" label={t('chargerLogs.update')} onClick={handleChargerUpdate} backgroundColor="dark" />
        </div>
      </ToolbarButtonContainer>
      {charger && (
        <>
          <ChargerDetailsTable>
            <tbody>
              <tr style={{ display: 'flex' }}>
                <td style={{ display: 'flex', width: '15%' }}>
                  <Icon path="general.charger" width="48px" height="48px" marginleft="24px" marginbottom="16px" marginright="16px" margintop="16px" />
                  <div>
                    <ChargerDetailsTitle>{t('chargerLogs.chargerName')}</ChargerDetailsTitle>
                    <ChargerDetailsValue>{charger.localName}</ChargerDetailsValue>
                  </div>
                </td>
                <td style={{ display: 'flex', width: '15%' }}>
                  <Icon path="general.charger" width="24px" height="24px" marginbottom="16px" marginright="16px" margintop="28px" />
                  <div>
                    <ChargerDetailsTitle>{t('chargerLogs.group')}</ChargerDetailsTitle>
                    <ChargerDetailsValue>{'NeedsToFetch'}</ChargerDetailsValue>
                  </div>
                </td>
                <td style={{ display: 'flex', width: '22.5%' }}>
                  <Icon path="chargers.user" width="24px" height="24px" marginbottom="16px" marginright="16px" margintop="28px" />
                  <div>
                    <ChargerDetailsTitle>{t('chargerLogs.email')}</ChargerDetailsTitle>
                    <ChargerDetailsValue>{charger.userEmail}</ChargerDetailsValue>
                  </div>
                </td>
                <td style={{ display: 'flex', width: '12.5%' }}>
                  <div>
                    <ChargerDetailsTitle>{t('chargerLogs.chargerId')}</ChargerDetailsTitle>
                    <ChargerDetailsValue>{charger.chargerId}</ChargerDetailsValue>
                  </div>
                </td>
                <td style={{ display: 'flex', width: '12.5%' }}>
                  <div>
                    <ChargerDetailsTitle>{t('chargerLogs.lastLog')}</ChargerDetailsTitle>
                    <ChargerDetailsValue>{'NeedsToFetch'}</ChargerDetailsValue>
                  </div>
                </td>
                <td style={{ display: 'flex', width: '12.5%' }}>
                  <div>
                    <ChargerDetailsTitle>{t('chargerLogs.serialNumber')}</ChargerDetailsTitle>
                    <ChargerDetailsValue>{charger.serialNumber == ' ' ? '-' : charger.serialNumber || '-'}</ChargerDetailsValue>
                  </div>
                </td>
                <td style={{ display: 'flex' }}>
                  <ChargerDetailsGoToChargerContainer>
                    <Navigator to={`/chargers/${charger.chargerId}`}><ChargerDetailsGoToChargerLabel>{t('chargerLogs.chargerDataSheet')}</ChargerDetailsGoToChargerLabel></Navigator>
                    <Icon path="general.forwardArrow" width="18px" height="18px" margintop="30px" color="primary" />
                  </ChargerDetailsGoToChargerContainer>
                </td>
              </tr>
            </tbody>
          </ChargerDetailsTable>
          <DateRangePickerContainer style={(!logs || logs.length < 1) ? { visibility: 'hidden' } : {}}>
            <DateRangePicker theme={theme} onSelect={(range) => handleChooseDateRange(range)} defaultDate={selectedDateRange} />
          </DateRangePickerContainer>
        </>
      )}
      <WhiteSpace height='16px' />
      {logs && logs.length > 0 && <>
        <Table id="ChargerLogs" height="calc(90vh - 235px)" isSearchEnabled={true} isSearchCtrlFStyle={true} isPaginationEnabled={false} exportObject={logs} columns={[
          { width: '15px' },
          { label: t('chargerLogs.lineNumber'), width: '7%' },
          { label: t('chargerLogs.time'), width: '6%' },
          { width: '7%' },
          { label: t('chargerLogs.type'), width: '7%' },
          { label: t('chargerLogs.message') }
        ]} data={logs.map(log => [
          {
            sortBy: log.type,
            custom: <LogIndicator $code={log.type == 'Code: 2' ? 'error' : log.type == 'Code: 6' ? 'warning' : 'normal'} />
          }, {
            sortBy: parseInt(log.lineNumber),
            value: {
              type: TableCellTypes.Label,
              value: log.lineNumber
            }
          }, {
            sortBy: parseInt(log.lineNumber),
            value: {
              type: TableCellTypes.Label,
              value: log.date.replace(/-/g, '.'),
            }
          }, {
            value: {
              type: TableCellTypes.Text,
              value: log.day
            }
          }, {
            sortBy: log.type,
            custom: <LogCode $code={log.type == 'Code: 2' ? 'error' : log.type == 'Code: 6' ? 'warning' : 'normal'}>{log.type}</LogCode>
          }, {
            sortBy: log.log,
            value: {
              type: TableCellTypes.Text,
              value: log.log.replace(/,/g, ', ')
            }
          }
        ])} />
      </>}
    </>
  )
}